<script>
import { ErrorMessage, Field } from 'vee-validate';

export default {
  name: 'PhoneNumber',

  data() {
    return {
      codeInternalValue: null,
    };
  },

  components: {
    ErrorMessage,
    Field,
  },

  props: {
    required: {
      type: Boolean,
      default: false,
    },

    optionLabel: {
      type: String,
    },

    optionValue: {
      type: String,
    },

    phoneName: {
      type: String,
      default: 'phone_number',
    },

    codeName: {
      type: String,
      default: 'country_code',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    code: {
      type: [Number, String, Object],
      default: null,
    },
  },

  computed: {
    countries() {
      return this.$store.state.countries.countriesCodeListRegistration;
    },
  },

  methods: {
    setCodeInternalValue(value) {
      if (value) {
        this.codeInternalValue = value;
      }
      return '';
    },
  },

  mounted() {
    if (this.code) {
      this.codeInternalValue = this.code;
    }
  },
};
</script>

<template>
  <div class="input-box">
    <div class="phone-item d-flex align-items-start">
      <div class="country_code" style="width: 40% !important">
        <Field v-slot="{ handleChange, value }" :name="codeName">
          <!-- TODO Enhance this part -->
          {{ setCodeInternalValue(value) }}
          <UtilsGenericDropDown
            v-model="codeInternalValue"
            :disabled="disabled"
            :empty-filter-message="$t('Noresultsfound')"
            :empty-message="$t('Noresultsfound')"
            :filter-fields="['name', 'code']"
            :filter-placeholder="$t('chooseacountry')"
            :loading="loading"
            :modelValue="value"
            :option-label="optionLabel"
            :option-value="optionValue"
            :options="countries"
            :placeholder="`${$t('code')}`"
            class="mb-0"
            close-on-scroll
            @change="$emit('update:code', codeInternalValue)"
            @update:modelValue="handleChange"
          />
        </Field>
      </div>
      <div class="full-box" style="width: 60% !important">
        <Field v-slot="{ field }" :name="phoneName">
          <InputText
            id="phoneNumber"
            :disabled="disabled"
            :name="phoneName"
            :placeholder="$t('profile.mobilenumber')"
            :required="required"
            class="w-100"
            type="number"
            v-bind="field"
          />
        </Field>
      </div>
    </div>
    <div class="d-flex flex-column">
      <ErrorMessage :name="codeName" class="text-danger" />
      <ErrorMessage :name="phoneName" class="text-danger" />
    </div>
  </div>
</template>

<style lang="scss">
.input-box {
  .phone-item {
    .p-dropdown-panel.p-component {
      @include dir(rtl) {
        right: 0;
        left: auto !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.input-box {
  .phone-item {
    gap: 5px;

    .p-dropdown.p-component.p-inputwrapper {
      .p-dropdown-label.p-inputtext {
        padding-right: 0;
        padding-left: 0;
        padding-inline-start: 5px;
      }

      .p-dropdown-trigger {
        width: auto;
        padding-right: 5px;
        padding-left: 5px;
      }
    }
  }
}

.country_code {
  .p-dropdown .p-dropdown-clear-icon {
    right: 1.5rem !important;
  }
}
</style>
